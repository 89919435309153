import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Button,
    Grid,
    IconButton,
    Typography as MuiTypography,
    Stack,
    TableCell,
    TableRow,
} from '@mui/material';
import { ReactComponent as Source } from 'assets/icons/Source.svg';
import { ReactComponent as SourceDarkMode } from 'assets/icons/SourceDarkMode.svg';
import MyIcon from 'assets/icons/import.svg';
import { format } from 'date-fns';
import { formatAccountTitle, formatCurrencyAmount } from 'utils/helpers';
import { ReactComponent as Trash } from 'assets/icons/Trash.svg';

export const ScanReceiptRow = ({
    themeMode,
    onEditClick,
    editId,
    handleEditClick,
    handleCollapse,
    index,
    row,
    isCollapse,
    importPostTransaction,
    handleRemoveTransaction,
    handleSourceModalOpen,
    isEdit,
}) => {
    return (
        <TableRow
            className="tableRowDesktop"
            hover
            onClick={() => isEdit && handleEditClick(row, index, editId)}
            sx={{
                cursor: isEdit ? 'pointer' : 'default',
            }}
        >
            <TableCell sx={{ maxWidth: 100 }}>
                <MuiTypography noWrap>
                    {format(new Date(row?.date), 'MM-dd-yyyy')}
                </MuiTypography>
            </TableCell>
            <TableCell sx={{ maxWidth: 200 }}>
                <MuiTypography>{row?.description}</MuiTypography>
            </TableCell>
            {!isEdit && (
                <TableCell sx={{ maxWidth: 200 }}>
                    <MuiTypography>
                        {row?.book_by_each_item
                            ? 'Booked By Each Item'
                            : formatAccountTitle(row?.account)}
                    </MuiTypography>
                </TableCell>
            )}
            <TableCell sx={{ textAlign: 'right' }}>
                <MuiTypography noWrap>
                    $ {formatCurrencyAmount(row?.transaction_total || 0)}
                </MuiTypography>
            </TableCell>
            <TableCell sx={{ maxWidth: 150 }}>
                <MuiTypography>{row?.counterparty_name}</MuiTypography>
            </TableCell>
            <TableCell sx={{ maxWidth: 75 }}>
                <MuiTypography
                    noWrap
                    sx={{
                        px: 1,
                        backgroundColor: !row?.is_paid ? '#FEF3F2' : '#ECFDF3',
                        color: !row?.is_paid ? '#B42318' : '#067647',
                        border: !row?.is_paid
                            ? ' 1px solid #FECDCA'
                            : '1px solid #ABEFC6',

                        borderRadius: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',

                        textTransform: 'capitalize',
                    }}
                >
                    {row?.is_paid ? 'Paid' : 'Unpaid'}
                </MuiTypography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center', maxWidth: 50 }}>
                {/* <Button
                    startIcon={
                        themeMode === 'dark' ? <SourceDarkMode /> : <Source />
                    }
                    onClick={(e) => {
                        e?.stopPropagation();
                        handleSourceModalOpen(index);
                    }}
                >
                    <Stack alignItems="flex-start" spacing={0} gap={0}>
                        <MuiTypography
                            variant="subtitle2"
                            color={themeMode === 'dark' ? '#fff' : '#202020'}
                            textTransform="none"
                        >
                            {row?.files?.[0]?.title}
                        </MuiTypography>
                        <MuiTypography
                            variant="subtitle2"
                            color="text.disabled"
                            textTransform="none"
                            fontSize={12}
                        >
                            {format(
                                new Date(row?.files?.[0]?.uploaded_at),
                                'mm-dd-yyyy, hh:mm',
                            )}
                        </MuiTypography>
                    </Stack>
                </Button> */}
                <IconButton
                    onClick={(e) => {
                        e?.stopPropagation();
                        handleSourceModalOpen(index);
                    }}
                >
                    {themeMode === 'dark' ? <SourceDarkMode /> : <Source />}
                </IconButton>
            </TableCell>
            <TableCell sx={{ maxWidth: 150 }}>
                <Stack
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={0.5}
                >
                    {isEdit && (
                        <Button
                            sx={{
                                backgroundColor: '#0061DB',
                                color: 'white',
                                borderRadius: '8px',
                                textTransform: 'none',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 0.5,
                                padding: 0,
                                paddingX: 0.75,
                                paddingY: 0.5,
                                '&:hover': {
                                    backgroundColor: '#0056b3',
                                    '@media (hover: hover)': {
                                        backgroundColor: '#0056b3',
                                    },
                                },
                            }}
                            onClick={(e) => {
                                e?.stopPropagation();
                                importPostTransaction(index);
                            }}
                        >
                            <img
                                src={MyIcon}
                                alt="Icon"
                                style={{
                                    width: 18,
                                    // height: 18,
                                }}
                            />
                            Import
                        </Button>
                    )}
                    {/* <Button
                        text="Edit"
                        startIcon={
                            themeMode === 'dark' ? (
                                <EditIconDarkMode fill={'#FFF'} />
                            ) : (
                                <EditIcon fill={'#FFF'} />
                            )
                        }
                        sx={{
                            background: (theme) =>
                                theme.backgroundColor.primary,
                            color: 'inherit',
                            borderRadius: '8px',
                            textTransform: 'none',
                            border: '1px solid #E5E6EB',
                            '&:hover': {
                                backgroundColor: (theme) =>
                                    theme.backgroundColor.primary,
                            },
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onEditClick(index);
                        }}
                    >
                        Edit
                    </Button> */}
                    {isEdit && (
                        <IconButton
                            color={isCollapse ? 'inherit' : 'default'}
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleRemoveTransaction(index);
                            }}
                        >
                            <Trash />
                        </IconButton>
                    )}
                    {row?.book_by_each_item ? (
                        isEdit || !!row?.transaction_items?.length ? (
                            <IconButton
                                onClick={(e) => {
                                    e?.stopPropagation();
                                    handleCollapse(index);
                                }}
                            >
                                {isCollapse ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </IconButton>
                        ) : null
                    ) : null}
                </Stack>
            </TableCell>
        </TableRow>
    );
};
