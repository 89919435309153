import SyncIcon from '@mui/icons-material/Sync';
import {
    Alert,
    Box,
    Button,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'store';
import {
    fetchBankAccountsFailure,
    fetchBankAccountsSuccess,
    fetchLinkTokenRequest,
    fetchUnimportedTransactionsRequest,
    setAccessTokenRequest,
    syncTransactionsRequest,
} from 'store/actions';
import { getBankAccounts } from 'store/services/bank.Service';
import { useCompanyService } from 'store/services/company.service';
import AddBankStartDateModal from './AddBankStartDateModal';
import ErrorAnimation from './Components/ErrorPopup';
dayjs.extend(isSameOrAfter);
const todayDate = dayjs(new Date()).format('YYYY-MM-DD');
const yesterdayDate = dayjs(todayDate).subtract(1, 'day');

function SyncBankAccounts({ onSyncAll, setIsLoading, setSyncStatus }) {
    const [selectedDate, setSelectedDate] = useState(yesterdayDate);
    const [isAddDateModalOpen, setAddDateModalOpen] = useState(false);

    const handleAddDateModalClose = useCallback(() => {
        setAddDateModalOpen(false);
    }, []);

    const handleAddDateModalOpen = useCallback(() => {
        setAddDateModalOpen(true);
        setSelectedDate(yesterdayDate);
    }, []);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const data = useSelector((state) => state?.company?.data);
    const companyId = data?.id;

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return date.toLocaleDateString('en-US', options);
    };
    const dispatch = useDispatch();
    const { accounts, loading, error } = useSelector((state) => state.bank);
    const { getUserAssociatedCompany, isLoading } = useCompanyService();
    const [initialLoading, setInitialLoading] = useState(true);
    const [isCreatLinkLoading, setIsCreateLinkLoading] = useState(false);
    const [errorState, setErrorState] = useState(null);
    const [linkToken2, setLinkToken2] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = useState(false);

    const handleSnackbarClose = useCallback(() => {
        setSnackbarMessage(false);
    }, []);

    useEffect(() => {
        const fetchUserCompanyAndBankAccounts = async () => {
            const { token } = store.getState().auth;
            try {
                if (companyId) {
                    const bankAccountsData = await getBankAccounts(
                        companyId,
                        token,
                    );
                    dispatch(fetchBankAccountsSuccess(bankAccountsData));
                } else {
                    setErrorState('No associated company found.');
                }
            } catch (error) {
                setErrorState(error.message);
                dispatch(fetchBankAccountsFailure(error.message));
            } finally {
                setInitialLoading(false);
            }
        };

        fetchUserCompanyAndBankAccounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUserAssociatedCompany, dispatch]);

    // useEffect(() => {
    //     const token = store.getState().auth.token;
    //     dispatch(fetchLinkTokenRequest({ token }));
    // }, [dispatch]);

    const onSuccess = useCallback(
        async (public_token, metadata) => {
            const { token } = store.getState().auth;
            try {
                dispatch(
                    setAccessTokenRequest({
                        publicToken: public_token,
                        token,
                        setSyncStatus,
                        companyId: data?.id,
                        setIsLoading,
                    }),
                );
            } catch (error) {
                console.error('Error setting access token:', error);
                setErrorState('Failed to set access token.');
            }
        },
        [data?.id, dispatch, setIsLoading, setSyncStatus],
    );

    const { open, ready } = usePlaidLink({
        token: linkToken2,
        onSuccess,
    });

    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    const createPlaidLinkToken = useCallback(
        (dateIn) => {
            const start_date = dayjs(dateIn).format('YYYY-MM-DD');
            const token = store.getState().auth.token;
            setIsCreateLinkLoading(true);
            dispatch(
                fetchLinkTokenRequest({
                    token,
                    start_date,
                    handleAddDateModalClose,
                    setIsCreateLinkLoading,
                    setLinkToken2,
                }),
            );
        },
        [dispatch, handleAddDateModalClose],
    );

    const [accountsError, setAccountsError] = useState(false);
    const [accountsErrorMsg, setAccountsErrorMsg] = useState('');
    const sync = useCallback(
        (id) => {
            const token = store.getState().auth.token;
            if (accounts?.length) {
                setIsLoading(true);
                const result = accounts?.map((item) => ({
                    id: item?.account_id,
                    name: item?.name,
                }));
                dispatch(syncTransactionsRequest({ token, onSyncAll, result }));
            } else {
                console.log('error');
                setAccountsErrorMsg(
                    `There's no accounts for sync transactions`,
                );
                setAccountsError(true);
            }
        },
        [accounts, dispatch, onSyncAll, setIsLoading],
    );
    const handleCloseAnimation = useCallback(() => {
        setAccountsError(false);
    }, []);

    const handleUnImport = useCallback(async () => {
        const { token } = store.getState().auth;
        const page = 1;
        if (accounts?.length) {
            setIsLoading(true);
            const result = accounts?.map((item) => ({
                id: item?.id,
                name: item?.name,
            }));
            accounts?.forEach((account, index) => {
                dispatch(
                    fetchUnimportedTransactionsRequest({
                        accountId: account.id,
                        page: page,
                        token: token,
                        ...(index === accounts?.length - 1 && {
                            onSyncAll,
                            result,
                        }),
                    }),
                );
            });
        } else {
            setAccountsErrorMsg(`There's no unimported transactions`);
            setAccountsError(true);
        }
    }, [accounts, dispatch, onSyncAll, setIsLoading]);

    const handleDateChange = useCallback((newDate) => {
        if (newDate && dayjs(newDate).isValid()) {
            setSelectedDate(dayjs(newDate));
        } else {
            setSelectedDate(null);
        }
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: isMobile ? 0 : 4,
                    flexDirection: isMobile ? 'column' : 'row',
                    width: '100%',
                }}
            >
                <Typography ml={1.8} pb={2} sx={{ fontWeight: '800' }}>
                    Sync to bank accounts
                </Typography>

                <Button
                    sx={{
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        color: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#9EA3AE'
                                : '#6C727F',
                        borderRadius: '8px',
                        outline: 'none',
                        textTransform: 'none',
                    }}
                    onClick={handleAddDateModalOpen}
                >
                    + Add new bank account
                </Button>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column-reverse', md: 'column' },
                }}
            >
                <TableContainer
                    sx={{
                        maxHeight: 360,
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-track': {
                            background: '#f1f1f1',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            background: '#555',
                        },
                    }}
                >
                    <Table>
                        <TableBody>
                            <TableRow
                                sx={{
                                    borderBottom: 'none',
                                    fontWeight: 'bold',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#212936'
                                            : '#ffffff',
                                }}
                            >
                                <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#4D5461',
                                    }}
                                >
                                    Account
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#4D5461',
                                        // Ensure it takes up the appropriate space
                                        // width: '10%', // Adjust this width as necessary
                                    }}
                                >
                                    Last synced
                                </TableCell>
                                {/* <TableCell
                                    sx={{
                                        borderBottom: 'none',
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#D3D5DA'
                                                : '#4D5461',
                                        // Ensure it takes up the appropriate space
                                        width: '15%', // Adjust this width as necessary
                                    }}
                                >
                                    Download Statement
                                </TableCell> */}
                            </TableRow>
                            {accounts?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{
                                        borderBottom: 'none',
                                        borderTop: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '1px solid #394150'
                                                : '1px solid #E5E6EB',
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#212936'
                                                : '#ffffff',
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            borderTop: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',

                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : 'black',
                                        }}
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            textAlign: 'right',
                                            borderTop: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : 'black',
                                        }}
                                    >
                                        {formatDate(row.last_synced)}
                                    </TableCell>
                                    {/* <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            textAlign: 'right',
                                            borderTop: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '1px solid #394150'
                                                    : '1px solid #E5E6EB',
                                            color: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#D3D5DA'
                                                    : 'black',
                                        }}
                                    > */}
                                    {/* {isDownloadLoading !== row?.id && (
                                            <IconButton
                                                onClick={() =>
                                                    handleDownload(row?.id)
                                                }
                                                sx={{
                                                    minWidth: 0,
                                                    padding: 0,
                                                }}
                                            >
                                                <DownloadIcon />
                                            </IconButton>
                                        )} */}
                                    {/* {isDownloadLoading === row?.id && (
                                            <CircularProgress
                                                size={19}
                                                sx={{
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#D3D5DA'
                                                            : 'black',
                                                }}
                                            />
                                        )} */}
                                    {/* </TableCell> */}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    display="flex"
                    justifyContent="end"
                    gap={2}
                    mt={5}
                    flexDirection={{ xs: 'column', md: 'row' }}
                >
                    <Button
                        sx={{
                            border: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '1px solid #394150'
                                    : '1px solid #E5E6EB',
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#9EA3AE'
                                    : '#6C727F',
                            borderRadius: '8px',
                            outline: 'none',
                            textTransform: 'none',
                        }}
                        onClick={handleUnImport}
                    >
                        UnImported Transactions
                    </Button>

                    <Button
                        startIcon={<SyncIcon />}
                        sx={{
                            backgroundColor: '#0061DB',
                            color: 'white',
                            textTransform: 'none',
                            borderRadius: '8px',
                            paddingX: '20px',
                            '&:hover': {
                                backgroundColor: '#0056b3',
                                '@media (hover: hover)': {
                                    backgroundColor: '#0056b3',
                                },
                            },
                        }}
                        onClick={sync}
                    >
                        Sync All
                    </Button>

                    {accountsError && (
                        <ErrorAnimation
                            onClose={handleCloseAnimation}
                            data={accountsErrorMsg}
                        />
                    )}
                </Box>
            </Box>
            <Snackbar
                open={snackbarMessage}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <AddBankStartDateModal
                handleDateChange={handleDateChange}
                open={isAddDateModalOpen}
                onClose={handleAddDateModalClose}
                selectedDate={selectedDate}
                createPlaidLinkToken={createPlaidLinkToken}
                isCreatLinkLoading={isCreatLinkLoading}
            />
        </>
    );
}

export default SyncBankAccounts;
