import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { CURRENCIES } from 'utils/data';
import { ACCOUNT_TYPES_1, ACCOUNT_TYPES_2 } from 'utils/helpers';

const AddNewAccountModal = ({
    open,
    onClose,
    onSave,
    isSubmitLoading,
    errors,
    accountNumber,
    accountName,
    accountDescription,
    parentAccount,
    parentAccounts,
    accountType1,
    accountType2,
    isPaymentMethod,
    isPanelDataLoading,
    handleAccountNumber,
    handleAccountName,
    handleAccountDescription,
    handleParentAccount,
    handleAccountType1,
    handleAccountType2,
    handleIsPaymentMethod,
    handleDropdownOpen,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                },
            }}
        >
            <DialogTitle
                sx={{
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    color: (theme) =>
                        theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                }}
            >
                Add New Transaction
            </DialogTitle>
            <DialogContent
                sx={{
                    minHeight: '270px',
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#555' : '#888',
                        borderRadius: '8px',
                        border: '2px solid transparent',
                        backgroundClip: 'content-box',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#777' : '#555',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '#2D3B4E'
                                : '#f1f1f1',
                        borderRadius: '8px',
                    },
                }}
            >
                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    mt={1}
                    border="none"
                    borderRadius="8px"
                    position="relative"
                    sx={{
                        border: 'none',
                    }}
                >
                    <Grid container spacing={3} alignItems="end">
                        <Grid item xs={6}>
                            <TextField
                                error={errors?.accountNumber}
                                label="Account Number"
                                type="text"
                                value={accountNumber}
                                onChange={handleAccountNumber}
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{
                                    position: 'relative',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                    },
                                }}
                            />
                            {errors?.accountNumber && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.accountNumber}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                error={errors?.accountName}
                                label="Account Name"
                                type="text"
                                value={accountName}
                                onChange={handleAccountName}
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{
                                    position: 'relative',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                    },
                                }}
                            />
                            {errors?.accountName && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.accountName}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={errors?.accountDescription}
                                label="Description"
                                type="text"
                                value={accountDescription}
                                onChange={handleAccountDescription}
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{
                                    position: 'relative',
                                    bgcolor: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#F4F4F6',
                                    borderRadius: '6px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB',
                                            borderRadius: '8px',
                                        },
                                    },
                                }}
                            />
                            {errors?.accountDescription && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.accountDescription}
                                </FormHelperText>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                                sx={{ position: 'relative' }}
                            >
                                <InputLabel error={errors?.paymentStatus}>
                                    Parent Account
                                </InputLabel>
                                <Select
                                    error={errors?.parentAccount}
                                    value={parentAccount}
                                    onChange={handleParentAccount}
                                    onOpen={handleDropdownOpen}
                                    label="Payment Status"
                                    sx={{
                                        bgcolor: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#394150'
                                                : '#F4F4F6', // Match the background color
                                        borderRadius: '6px', // Match border radius
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? 'none'
                                                    : '#E5E6EB', // Match border color
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderColor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '#E5E6EB', // Hover effect
                                            },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                            {
                                                borderColor: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'none'
                                                        : '#E5E6EB', // Focus effect
                                            },
                                    }}
                                >
                                    {isPanelDataLoading ? (
                                        <MenuItem
                                            disabled
                                            sx={{
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <CircularProgress size={24} />
                                        </MenuItem>
                                    ) : (
                                        parentAccounts.map((status) => (
                                            <MenuItem
                                                key={status.id}
                                                value={status.id}
                                            >
                                                {status.label}
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                            {errors?.parentAccount && (
                                <FormHelperText
                                    error
                                    sx={{ position: 'absolute' }}
                                >
                                    {errors?.parentAccount}
                                </FormHelperText>
                            )}
                        </Grid>
                        {parentAccount === -1000 && (
                            <Grid item xs={6}>
                                <FormControl
                                    key={1}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    sx={{ position: 'relative' }}
                                >
                                    <InputLabel error={errors?.accountType1}>
                                        Account Type 1
                                    </InputLabel>
                                    <Select
                                        error={errors?.accountType1}
                                        value={accountType1}
                                        onChange={handleAccountType1}
                                        label="Payment Status"
                                        sx={{
                                            bgcolor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#F4F4F6', // Match the background color
                                            borderRadius: '6px', // Match border radius
                                            '& .MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '#E5E6EB', // Match border color
                                                },
                                            '&:hover .MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '#E5E6EB', // Hover effect
                                                },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '#E5E6EB', // Focus effect
                                                },
                                        }}
                                    >
                                        {ACCOUNT_TYPES_1.map((status) => (
                                            <MenuItem
                                                key={status.value}
                                                value={status.value}
                                            >
                                                {status.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                {errors?.accountType1 && (
                                    <FormHelperText
                                        error
                                        sx={{ position: 'absolute' }}
                                    >
                                        {errors?.accountType1}
                                    </FormHelperText>
                                )}
                            </Grid>
                        )}
                        {parentAccount === -1000 && (
                            <Grid item xs={6}>
                                <FormControl
                                    key={2}
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    sx={{ position: 'relative' }}
                                >
                                    <InputLabel error={errors?.accountType2}>
                                        Account Type 2
                                    </InputLabel>
                                    <Select
                                        label="Payment Status"
                                        error={errors?.accountType2}
                                        value={accountType2}
                                        onChange={handleAccountType2}
                                        sx={{
                                            bgcolor: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#F4F4F6', // Match the background color
                                            borderRadius: '6px', // Match border radius
                                            '& .MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '#E5E6EB', // Match border color
                                                },
                                            '&:hover .MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '#E5E6EB', // Hover effect
                                                },
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                {
                                                    borderColor: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? 'none'
                                                            : '#E5E6EB', // Focus effect
                                                },
                                        }}
                                    >
                                        {ACCOUNT_TYPES_2?.[accountType1]?.map(
                                            (status) => (
                                                <MenuItem
                                                    key={status.value}
                                                    value={status.value}
                                                >
                                                    {status.label}
                                                </MenuItem>
                                            ),
                                        )}
                                    </Select>
                                </FormControl>
                                {errors?.accountType1 && (
                                    <FormHelperText
                                        error
                                        sx={{ position: 'absolute' }}
                                    >
                                        {errors?.accountType1}
                                    </FormHelperText>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        size="small"
                                        checked={isPaymentMethod}
                                        onChange={(event) =>
                                            handleIsPaymentMethod(event)
                                        }
                                    />
                                }
                                label={
                                    <Typography sx={{ userSelect: 'none' }}>
                                        Is Payment Method
                                    </Typography>
                                }
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 2,
                    bgcolor: (theme) =>
                        theme.palette.mode === 'dark' ? '#212936' : 'white',
                }}
            >
                <Button
                    sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#6C727F',
                        border: (theme) =>
                            theme.palette.mode === 'dark'
                                ? '1px solid #394150'
                                : '1px solid #E5E6EB',
                        textTransform: 'none',
                        marginRight: '10px',
                    }}
                    size="small"
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <LoadingButton
                    sx={{
                        backgroundColor: '#0061DB',
                        color: 'white',
                        borderRadius: '8px',
                        textTransform: 'none',
                        paddingX: '20px',
                        paddingY: '8px',
                        '&:hover': {
                            backgroundColor: '#004BB5',
                        },
                        width: {
                            xs: '100%',
                            sm: 'auto',
                        },
                    }}
                    loading={isSubmitLoading}
                    size="small"
                    onClick={() =>
                        onSave(
                            accountNumber,
                            accountName,
                            accountDescription,
                            parentAccount,
                            accountType1,
                            accountType2,
                            isPaymentMethod,
                        )
                    }
                >
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default AddNewAccountModal;
